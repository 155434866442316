import React from "react";
import ClientsData from "data/sections/clients.json";
import ClientsData2 from "data/sections/clients2.json";
import Split from "../Split";
import Slider from "react-slick";

const Clients = ({ theme }) => {
  var first = ClientsData.slice(0, ClientsData.length / 2);
  var second = ClientsData.slice(4, ClientsData.length);
  var third = ClientsData2.slice(0, ClientsData2.length / 2);
  var fourth = ClientsData2.slice(4, ClientsData2.length);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true
  };
  return (
    <section className="clients section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 valign md-mb50">
            <div className="sec-head mb-0">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                CLIENTIELE
              </h6>
              <h4 className="wow mb-20 fw-600 color-font">Our Clients & Projects</h4>
              <p>
              some of the amazing clients we have had the privilege to work with and exciting projects we have undertaken.
              </p>
            </div>
          </div>
          <div className="col-lg-8">
            <Slider
              {...settings}
              className="slic-item-brand wow fadeInUp"
              data-wow-delay=".5s"
            >
              <div className="item-brand">
                <div>
                  <div className="row bord">
                    {first.map((item) => (
                      <div key={item.id} className="col-md-3 col-6 brands">
                        <div
                          className="item wow fadeIn brand-images"
                          data-wow-delay={`${item.id == 1
                            ? ".3"
                            : item.id == 2
                              ? ".6"
                              : item.id == 3
                                ? ".8"
                                : item.id == 4
                                  ? ".3"
                                  : ""
                            }s`}
                        >
                          <div className="img">
                            {theme === "light" ? (
                              <img src={item.lightImage} alt="" />
                            ) : (
                              <img src={item.darkImage} alt="" />
                            )}
                          
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    {second.map((item) => (
                      <div
                        key={item.id}
                        className={`${item.id == 5
                          ? "col-md-3 col-6 brands sm-mb30"
                          : item.id == 6
                            ? "col-md-3 col-6 brands sm-mb30"
                            : item.id == 7
                              ? "col-md-3 col-6 brands"
                              : item.id == 8
                                ? "col-md-3 col-6 brands"
                                : ""
                          }`}
                      >
                        <div
                          className="item wow fadeIn brand-images"
                          data-wow-delay={`${item.id == 1
                            ? ".4"
                            : item.id == 2
                              ? ".7"
                              : item.id == 3
                                ? ".5"
                                : item.id == 4
                                  ? ".3"
                                  : ""
                            }s`}
                        >
                          <div className="img">
                            {theme === "light" ? (
                              <img src={item.lightImage} alt="" />
                            ) : (
                              <img src={item.darkImage} alt="" />
                            )}
              
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="item-brand">
                <div>
                  <div className="row bord">
                    {third.map((item) => (
                      <div key={item.id} className="col-md-3 col-6 brands">
                        <div
                          className="item wow fadeIn brand-images"
                          data-wow-delay={`${item.id == 1
                            ? ".3"
                            : item.id == 2
                              ? ".6"
                              : item.id == 3
                                ? ".8"
                                : item.id == 4
                                  ? ".3"
                                  : ""
                            }s`}
                        >
                          <div className="img">
                            {theme === "light" ? (
                              <img src={item.lightImage} alt="" />
                            ) : (
                              <img src={item.darkImage} alt="" />
                            )}
                   
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    {fourth.map((item) => (
                      <div
                        key={item.id}
                        className={`${item.id == 5
                          ? "col-md-3 col-6 brands sm-mb30"
                          : item.id == 6
                            ? "col-md-3 col-6 brands sm-mb30"
                            : item.id == 7
                              ? "col-md-3 col-6 brands"
                              : item.id == 8
                                ? "col-md-3 col-6 brands"
                                : ""
                          }`}
                      >
                        <div
                          className="item wow fadeIn brand-images"
                          data-wow-delay={`${item.id == 1
                            ? ".4"
                            : item.id == 2
                              ? ".7"
                              : item.id == 3
                                ? ".5"
                                : item.id == 4
                                  ? ".3"
                                  : ""
                            }s`}
                        >
                          <div className="img">
                            {theme === "light" ? (
                              <img src={item.lightImage} alt="" />
                            ) : (
                              <img src={item.darkImage} alt="" />
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Slider>

          </div>
        </div>
      </div>
    </section>
  );
};

export default Clients;

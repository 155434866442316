import React from "react";
import { Link } from "gatsby";
import introData from "data/sections/intro.json";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation, Pagination, Parallax } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import removeSlashFromPagination from "common/removeSlashpagination";
import fadeWhenScroll from "common/fadeWhenScroll";

SwiperCore.use([Navigation, Pagination, Parallax]);

const IntroWithSlider = ({ sliderRef }) => {
  const [load, setLoad] = React.useState(true);
  React.useEffect(() => {
    fadeWhenScroll(document.querySelectorAll(".fixed-slider .caption"));
    setTimeout(() => {
      removeSlashFromPagination();
      setLoad(false);
    }, 1000);
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  return (
    <header
      ref={sliderRef}
      className="slider slider-prlx fixed-slider text-center"
    >
      <div className="swiper-container parallax-slider">
        {!load ? (
          <Swiper
            speed={1000}
            parallax={true}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            pagination={{
              type: "fraction",
              clickable: true,
              el: paginationRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.params.pagination.el = paginationRef.current;
            }}
            onSwiper={(swiper) => {
              setTimeout(() => {
                for (var i = 0; i < swiper.slides.length; i++) {
                  swiper.slides[i].childNodes[0].setAttribute(
                    "data-swiper-parallax",
                    0.75 * swiper.width
                  );
                }

                swiper.params.navigation.prevEl = navigationPrevRef.current;
                swiper.params.navigation.nextEl = navigationNextRef.current;

                swiper.params.pagination.el = paginationRef.current;

                swiper.navigation.destroy();
                swiper.navigation.init();
                swiper.navigation.update();

                swiper.pagination.destroy();
                swiper.pagination.init();
                swiper.pagination.update();
              });
            }}
            className="swiper-wrapper"
            slidesPerView={1}
          >
            {introData.map((slide) => (
              slide.id === 1 ? 
              <SwiperSlide key={slide.id} className="swiper-slide">
                <div
                  className="bg-img valign"
                  style={{ backgroundImage: `url(${slide.image})` }}
                  data-overlay-dark="6"
                >
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-md-12">
                        <div className="caption center home-title-padd">
                          {/* <h1 className="color-font">{slide.title}</h1> */}
                          {slide?.content && <p class="title-slide fw-300">{slide.content.text1}  <span className="title-slide-sec">{slide.content.text2}</span> {slide.content.text3}  <span className="title-slide-sec"> {slide.content.text4} </span></p >}
                          <Link to="/about" className="butn bord curve mt-30">
                            <span>Know More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              : 
              <SwiperSlide key={slide.id} className="swiper-slide">
              <div
                className="bg-img valign"
                style={{ backgroundImage: `url(${slide.image})` }}
                data-overlay-dark="6"
              >
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-6 col-md-6 ">
                      <div className="caption center ">
                        {/* <h1 className="color-font">{slide.title}</h1> */}
                        {slide?.content && <p class="title-slide fw-300 home-title-left"> <span className="title-slide-sec">{slide.content[0]}</span> {slide.content[1]} </p >}
                        {slide?.content && <p class="title-slide fw-300 home-title-left"> {slide.content[2]} <span className="title-slide-sec">{slide.content[3]}  </span></p>}
                        {slide?.content && <p class="title-slide fw-300 home-title-left"> {slide.content[4]} <span className="title-slide-sec">{slide.content[5]} </span></p>}
                        <Link to="/about" className="butn bord curve mt-30 home-know-btn">
                          <span>Know More</span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="caption center mt-30">
                      <div class="mb-50 mt-50"><img src="/img/slid/ad6.png" alt="" /></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
           
            ))}
          </Swiper>
        ) : null}
        <div className="setone setwo">
          <div
            ref={navigationNextRef}
            className="swiper-button-next swiper-nav-ctrl next-ctrl cursor-pointer"
          >
            <i className="fas fa-chevron-right"></i>
          </div>
          <div
            ref={navigationPrevRef}
            className="swiper-button-prev swiper-nav-ctrl prev-ctrl cursor-pointer"
          >
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        <div ref={paginationRef} className="swiper-pagination top botm"></div>

        <div className="social-icon">
        <a target="_blank" href="https://www.linkedin.com/company/imediarc">
          <i class="fab fa-linkedin-in"></i>
          </a>
          <a  target="_blank" href="https://www.instagram.com/imediarc/">
            <i class="fab fa-instagram"></i>
          </a>
          <a  target="_blank" href="https://www.facebook.com/imediarc">
          <i className="fab fa-facebook-f"></i>
          </a>
        </div>
      </div>
    </header>
  );
};

export default IntroWithSlider;

import React from "react";
import { Link } from "gatsby";
import AboutUs1Date from "../../data/sections/about-us1.json";

const AboutUs = () => {
  return (
    <section className="about-us section-padding-about">
      <div className="container">
        <div className="row">

          <div className="col-lg-6 img md-mb50">
            <div class="">
              <img src={AboutUs1Date.image} alt="" />
              <div className="stauts">
                {AboutUs1Date.stauts.map((item) => (
                  <div className="item" key={item.id}>
                    <h4 >
                      {item.number}
                      <span>{item.letter}</span>
                    </h4>
                    <h6>{item.stautsName1} <br/> {item.stautsName2}</h6>
       
                  </div>
                ))}
              </div>
            </div>

          </div>
          <div className="col-lg-6 valign">
            <div className="">
              <h6 className="ls10 mb-10 ">
                {AboutUs1Date.smallTitle}
              </h6>
              <h4 className="fw-600  ls1 mb-30 color-font">
                {AboutUs1Date.title}
              </h4>
              <p>{AboutUs1Date.content}</p>
              <Link to="/about" className="butn bord curve mt-30">
                <span>Read More</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
